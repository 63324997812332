<template>
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <el-button type="warning" @click="addsheng">添加省份</el-button>
        <el-button type="warning" @click="clear_hc">清除缓存</el-button>
        <!-- <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b"> -->
        <!-- 搜索表单 -->
        <!-- <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="10">
                <el-col :lg="5" :md="14">
                  <el-form-item label-width="120px" label="关键字">
                    <el-input
                      v-model="where.name"
                      placeholder="被推广者/推广人员"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="5" :md="14">
                  <el-form-item label-width="120px" label="注册时间">
                   <el-input
                      v-model="where.name"
                      placeholder="注册时间"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="5" :md="14">
                  <el-form-item label-width="120px" label="推广人员归属">
                          <el-input
                      v-model="where.name"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form> -->
        <!-- </div>
        </div> -->
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>

              <!-- <span
                style="
                  font-size: 18px;
                  font-weight: 700;
                  color: #4e4e4e;
                  margin-left: 30px;
                "
                >累计提现：{{ total }}</span
              >
              <span
                style="font-size: 18px; font-weight: 700; color: #fea837"
                >{{
              }}</span>
              <span
                style="
                  font-size: 18px;
                  font-weight: 700;
                  color: #4e4e4e;
                  margin-left: 20px;
                "
                >可用金额：{{ in_hand }}</span
              >
              <span
                style="font-size: 18px; font-weight: 700; color: #4e4e4e"
                >{{
              }}</span> -->
            </div>
            <div></div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <el-table
              :data="tableData"
              style="width: 100%"
              row-key="id"
              border
              lazy
              :load="load"
              :key="currkey"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            >
              <el-table-column prop="id" label="编号" width="180">
              </el-table-column>
              <el-table-column prop="name" label="城市名称" width="180">
              </el-table-column>
              <el-table-column prop="parent_name" label="上级名称">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="warning"
                    @click="handleAdd(scope.$index, scope.row)"
                    >添加</el-button
                  >
                  <el-button
                    size="mini"
                    type="success"
                    @click="handleEdit(scope.$index, scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-card>
    </div>

    <el-dialog title="城市添加" :visible.sync="dialogVisible" width="30%">
      <!-- <span>这是一段信息</span> -->
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="城市名称">
          <el-input
            type="text"
            v-model="form.name"
            placeholder="请输入城市名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="城市编码">
          <el-input
            type="text"
            v-model="form.code"
            placeholder="请输入城市编码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addcity">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
// 引入自定义表格组件
import MyTable from "@/components/MyTable";
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import { get_citylist, get_clean, add_city, del_city } from "@/api/system";

export default {
  name: "index",
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
  },
  // Data数据
  data() {
    return {
      currkey: 0,
      form: {
        name: "",
        code: "",
        parent_code: "",
        type: "",
      },

      // 表格搜索条件
      //存储域名地址
      domain: "",
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      tableData: [],
      dialogVisible: false,
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      //用来控制单条的数据
      row: {
        http: "",
        url: "",
      },
      // 表头数据
      // 列表设置弹窗
      ListSetupShow: false,
      in_hand: 0,
    };
  },
  // // 生命周期 - 挂载完成（可以访问 DOM 元素）
  // mounted() {
  //   console.log("666666");
  //   this.getList();
  // },
  // updated(){
  //   this.getList();
  // },
  filters: {
    formatDate(value, format) {
      if (!value) return "";
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  activated() {
    this.getList();
  },
  // 方法集合
  methods: {
    handleDelete(index, row) {
      console.log(row);
      this.$confirm("此操作将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("666");
          console.log(row.code);
          del_city({ code: row.code }).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg);
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    addsheng() {
      this.dialogVisible = true;
      this.form.type = 0;
    },
    load(tree, treeNode, resolve) {
      console.log(tree.code, treeNode);
      let obj = {
        parent_code: tree.code,
      };
      get_citylist(obj).then((res) => {
        resolve(res.data);
      });
    },
    //添加城市
    handleAdd(index, row) {
      this.form = {
        name: "",
        code: "",
        parent_code: "",
        type: "",
      };
      console.log(index, row);
      this.dialogVisible = true;
      this.form.parent_code = row.code;
      this.form.type = row.type;
    },
    handleEdit(index, row) {
      this.form = {
        name: "",
        code: "",
        parent_code: "",
        type: "",
      };
      console.log(index, row);
      this.dialogVisible = true;
      this.form.parent_code = row.code;
      this.form.type = row.type;
      this.form.id = row.id;
      this.form.name = row.name;
      this.form.code = row.code;
    },
    addcity() {
      if(this.form.name == "" || this.form.code == ""){
        this.$message.error("请填写完整信息");
        return false;
      }
      add_city(this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dialogVisible = false;
          this.getList();
          this.form = {
            name: "",
            code: "",
            parent_code: "",
            type: "",
          };
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //清除缓存
    clear_hc() {
      this.$confirm("此操作将清除缓存, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("666");
          get_clean().then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "清除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getList() {
      get_citylist().then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          this.tableData = res.data;
          this.currkey += 1;
        }
        //   this.List = res.data;
      });
    },
    affirm(row) {
      // console.log(row);
      let that = this;
      this.$utils.confirm(
        "确定要执行此操作吗？",
        function () {
          // 执行一些需要的逻辑
          shared_extractpass(row.id)
            .then((res) => {
              // console.log(res)
              if (res.code == 200) {
                that.$message.success(res.msg);
                that.getList();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch((err) => {
              that.$message.error(err.msg);
            });
        },
        "确定",
        "warning"
      );
    },
    refuse(row) {
      let that = this;
      this.$utils.confirm(
        "确定要执行此操作吗？",
        function () {
          // 执行一些需要的逻辑
          shared_extractreject(row.id)
            .then((res) => {
              // console.log(res)
              if (res.code == 200) {
                that.$message.success(res.msg);
                that.getList();
              } else {
                that.$message.error(res.msg);
              }
            })
            .catch((err) => {
              that.$message.error(err.msg);
            });
        },
        "确定",
        "warning"
      );
    },
    closet() {
      this.dialogVisible = false;
      this.domain = "";
    },
    // 查询条件
    QueryList() {
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },
    // 当前页数改变事件
    currentChange(val) {
      // console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
    },
    ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.w-50 {
  width: 400px;
}
.youbianjudingshirenwu {
  margin-left: 15px;
}
.zuobianjudingshirenwu {
  margin-right: 15px;
}
</style>
